import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const Youtube = makeShortcode("Youtube");
const Outro = makeShortcode("Outro");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
      <p>{`2022年はむかーしやってた趣味の楽曲制作を再開して延々とやっていました。ハードテクノってジャンルのものです。日々夜中まで……（あとゲーム）。で、音楽のために試すものはひたすら、好きなだけ試そうという風に考えてやっていたので、めちゃくちゃ色々買うなりしました。それらについて記事にするなりすると良さそうだと思うものの、それより音楽やってたほうがいいなってことで全然ブログ的な物を書いてこなった。そんなわけでとりあえず散財の記録……。`}</p>
    </Intro>
    <Body mdxType="Body">
      <p>{`買ったものを列挙していこうと思ったけど、色々思うところを書いていったら長くなりました。まぁTakazudoはこの1年こういうことを1人で延々とやってたんですよという共有ということで、結果的に個人的な振り返り。`}</p>
      <h2 {...{
        "id": "ableton-live"
      }}>{`Ableton Live`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/c6d5ef9f-a1df-4703-b981-bcca5e72a174_live.png?auto=compress,format&w=4112&h=2570",
          "alt": "Ableton Liveの画面"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.ableton.com/en/live/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Ableton Live`}</a></li>
      </ul>
      <p>{`自分は高校〜社会人になって数年の間はかなり音楽やってて、バイトしてためたお金でシンセサイザーなりソフトなりを買ったりしてるDTM野郎でした。このAbleton Liveはたぶんそういう音楽を作るためのベースとなるDAW（Digital Audio Workstation）界隈では今おそらく一番シェア高いんでは？というぐらいのソフト。`}</p>
      <p>{`自分はこれをバージョン1だか2だか（20年ぐらい前）の頃から使っていたので、ひとまずこれをアップグレード。そんな太古の時代に買ったやつなのに安くアップグレード版が買えて良心的である👌`}</p>
      <p>{`DAWの良し悪しは色々使っているわけではないので分からない。でもLiveはUIが色々凝っていて、システムちっくな見た目じゃないのも好きで使っている理由だと思う。まぁ今ではすっかりメインストリームなやつって位置づけのソフトになっているけど。`}</p>
      <h2 {...{
        "id": "korg-nanokey2--nanopad2"
      }}>{`Korg nanoKEY2 / nanoPAD2`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/6ab1124b-93f4-4377-93a9-2044a2e6dbcd_korg.png?auto=compress,format&w=2330&h=1484",
          "alt": "Korg nanoKEY2とnanoPAD2"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.korg.com/jp/products/computergear/nanokey2/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Korg nanoKEY2`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.korg.com/jp/products/computergear/nanopad2/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Korg nanoPAD2`}</a></li>
      </ul>
      <p>{`そういう音楽のソフトって、仕組み上、いろんなツマミを回したり、ピアノロールっていう、音階を表現したエディタみたいなやつにポツポツ音符的なものを置いていくんだけれども、そういうのをやりやすくするためのデバイスがある。それがこういうMIDIコントローラーとかMIDIキーボードと言われるもの。`}</p>
      <p>{`で、自分はあんまりデカイのを買っても場所をとる割にはイマイチだと思っていたので、ひとまず小さくて安いやつを買った。そもそも自分はもともと何か楽器が弾けるような人間では無いのと、自分が好きな音楽というのは、何かメロディやコード進行みたいなものがしっかりあるやつでは無いので、「キーボードです！」みたいなのがあっても弾けないのである。役割として、音を確認するための道具となる。`}</p>
      <p>{`このnanoKEY2とnanoPAD2はそういう意味でちょうど良さそうと思ったのだが、結果的にすぐ使わなくなり、売ることになる。ちなみに自分の好きな音楽と言うのはこういうやつです。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://music.youtube.com/playlist?list=LRYRGrRyrPI55j73lFQZ9NlW4_7xap9XipMo6&feature=share",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Youtube Music - Takazudo 2022 Recap`}</a></li>
      </ul>
      <h2 {...{
        "id": "ableton-push2"
      }}>{`Ableton Push2`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/1b5cfab7-1123-4aa1-b013-a82fa9c7f2e0_push2.png?auto=compress,format&w=3538&h=2198",
          "alt": "Push2の写真"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.ableton.com/ja/push/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Ableton Push2`}</a></li>
      </ul>
      <p>{`さて、そんなわけで色々曲を作ってみようとするのだけれど、どうやったら曲になるのかがよく分からなくなってくる。何日もAbleton Liveに向かってあれこれいじってみるも、完成しないまま時間が過ぎるみたいな。昔も結構やってたはずなんだけど、どうやってたんだっけ？ その時も腑に落ちないまま延々と作り続けていた気がする。高校／大学のころはその頃の音楽仲間と一緒になってライブみたいのも少しやっていたりしたんだけど、時間が経ちすぎて自分が変化していてよく分からなくなってくる。`}</p>
      <p>{`そんな時に、これって良さそうじゃない？と思ってこのPush2を買ってみる。これは、実質Ableton Live公式の、Live専用コントローラーと言える。Abletonは2022年、LiveとセットでこのPush2を売っていこうというスタンスを明確にした。実質、Liveならコレとセットで使うべしというモノ。`}</p>
      <p>{`これを買っていじってみると、「これはすごい」と感じた。パソコン上で動くそういう音楽ソフトって、いかによくできていようと、マウスでつまみをいじるなりポチポチピアノロールをいじるなり、そういう繰り返しになる。先述したMIDIコントローラーなりは、それを補助するものだが、あくまで補助という感覚になる。ところがこのPush2は、Liveの操作をとんでもなくスムーズに出来るように作られている。LiveとPush2でひとつなんだと思わせるぐらいに。`}</p>
      <p>{`白黒の鍵盤と全く違うノート入力インターフェースも備えていて、これ一体なんなんだろう、Abletonが考えたんだろうか。おそらく楽器を弾けない人でも音楽をできるように配慮されて作られていることを感じることができ、これはスゲェとなった。`}</p>
      <p>{`ただ、その後も色々方法を模索しているうちに、Push2は自分には不要かもと思うよくになり、将来的に売るかもしれない。Push2自体は素晴らしいプロダクトとは思うのだけれど、自分の1年ぐらいこれを使ってきた印象としては、Push2は汎用的に作られているように思われ、おそらく自分の興味の向いている方向性とは少しズレている。`}</p>
      <h2 {...{
        "id": "fader-pro"
      }}>{`Fader Pro`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://faderpro.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Fader Pro`}</a></li>
      </ul>
      <p>{`このFader Proというのは音楽ソフトでは無くて、月額制の作曲講座動画見放題サービスみたいなやつ。これ、その界隈では著名なアーティストが、どういう事を考えてどうやって曲を作っているかを解説してくれている動画が色々見れる。`}</p>
      <p>{`自分が高校生、大学生の頃にはこんなの無かった。完全に暗中模索だったのに、今はいい時代になったもんだ……。このサービスで動画をかなり見て、えらい勉強になった。あとYouTubeなんかでもたくさんの解説動画あるし、2022年すごい。`}</p>
      <p>{`ついでに英語の勉強にもなってる。`}<br parentName="p"></br>{`
`}{`カンケーないけど、自分の英語の学び方はすべてこういう興味ドリブンです。`}</p>
      <h2 {...{
        "id": "splice"
      }}>{`Splice`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://splice.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Splice`}</a></li>
      </ul>
      <p>{`Fader Proで学んだことはかなりデカい。その中で紹介されていたのがこのSpliceというサービス。これは何かというと、これも月額制で毎月一定のクレジットが得られ、それを使ってロイヤリティーフリーの音素材を、一つ一つ選んでいたら使い切れないほど大量にダウンロードできるというもの。`}</p>
      <p>{`自分が大学生の頃、バイトなりでためたお金で、サンプリングCDというものをちょくちょく買っていた。サンプリングCDとは、そういうロイヤリティーフリーの音素材が入っているもので、値段もそれなりにする。テーマごとに分けられるなりしていて、4,000円とか5,000円とかする。高い。その内容はといえば、なんかインド人の声がいっぱい入ってるとか、ドラムのループが入ってるとかそういうやつ。その頃友人に「え？これを6,000円で買うの？理解不能理解不能！」みたいなことを言われていた。それが今、月額1,000円ぐらいで無限の選択肢の中から選び放題なわけですよ。2022年すごい。`}</p>
      <p>{`結果的に、自分の音楽の中では、何かしらの素材を使う割合は多くないのだけれど、それでもsubscriptionをやめると0クレジットに戻ってしまうので、なかなかやめることも出来ないし、月額1,000円ぐらいならまいっかと思わせる、恐ろしくよく出来たサービスと言える。`}</p>
      <h2 {...{
        "id": "cableguys-kickstart"
      }}>{`Cableguys Kickstart`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/1e4cbfcf-ac15-42df-9af8-46fbe97f96bf_kickstart.png?auto=compress,format&w=2416&h=1270",
          "alt": "Kickstartのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.kickstart-plugin.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Cableguys Kickstart`}</a></li>
      </ul>
      <p>{`ここからはFader Proで見たので買いましたシリーズ。まずはKickstartというプラグイン。Fader Proでかなり多くのアーティストが使っていたので買った。`}</p>
      <p>{`これは、端的に言うと、4つ打ちのキックのタイミングで音のボリュームを下げるエフェクト。これは、トラックにside chain compressionでキックのトラックを指定して、キックを立たせるということがこのジャンルの音楽ではよく使われるテクニックらしいのだが、それを何も考えずにできるようにしたみいなやつ。`}</p>
      <p>{`このプラグインの存在と言うか、そういうsound makingのテクニックみたいのが色々知れたのがFader Proなり見てて大きかったかも。なんか、割とトラックを重ねて、うーんいまいちだけでよくわかりませ〜んみたいなので終わってたんで。そういうのを動画で教えてくれるのはホントいいことだよ……`}</p>
      <h2 {...{
        "id": "d16"
      }}>{`d16`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/2e0f50c6-a0b6-4c83-9cc8-dc5a1f715f01_d16.png?auto=compress,format&w=3776&h=1320",
          "alt": "d16プラグインのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://d16.pl/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`d16`}</a></li>
      </ul>
      <p>{`Fader Proらを見ておすすめされていて買ったもので、このd16という会社のプラグインは買って良かったものだった。`}</p>
      <p>{`音楽をやっていくというのは、自分の場合、自分はどういう音楽が好きなのか？をひたすらに突き詰めていくような行為という側面があるっぽい。このへんは人それぞれだとは思うのだけれど。そして、このd16という会社のエフェクトなりは、自分の好きな音を作るために必要な要素だった。最初は1つ2つをセールで買ったのだけれど、そういうセールがあるたびに買い足し、結果的に売ってるエフェクトは2/3ぐらい持っているという結果になっている。`}</p>
      <p>{`そもそもそういうエフェクトって、もともとAbleton Liveに付属しているものがたくさんあるんで、要らなくない？と思っていたのだが、自分としてはかなりこの会社の趣向に合っているんだと思う。まぁ本当はLive付属のものでも出来るとは思うんだけれど、どうなんだろう。やはりそういうオーディオの処理をまとめたものがそれで一つの道具となっているという感じなのだろうか。俺はマラソンの時はやっぱりNikeのスニーカーじゃないとだめなんだよねみたいな感じか？`}</p>
      <p>{`モノとして、プラグインの性能の割には安価であるとも思う。`}</p>
      <h2 {...{
        "id": "audioreakt"
      }}>{`Audioreakt`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://audioreakt.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Audioreakt`}</a></li>
      </ul>
      <p>{`Fader Proで知りましたシリーズ。このAudioreaktというのは、Ableton Live付属のソフトシンセのパッチなんかを売っている会社。で、ここのそういう商品をちょっと買った。`}</p>
      <p>{`これは色々とあるあるなヤツだと思うんだけど、現代はコンテンツ過多の時代と言ってしまっていいかと思う。NetflixやSpotifyで映像や音楽は見放題聴き放題。これは音楽のソフトについても言える。楽曲制作界隈の情報を日々見ていると、こういうすごいプラグインです、ソフトウェアシンセサイザーです、セールです！みたいなのが毎日のように流れてくる。それら一つ一つにはそれなりに個性があるので、何かそういうのを見ているだけで疲れてしまうというか、それ使って試すだけで日々が過ぎていくというか、自分が本当に必要なもの、欲しい物って何……？みたいな気分にもなってきたりする。お前は音楽をやりたいのかソフトを買いたいのかみたいなそういう感じ。まぁそういうのを色々試すのもそこそこ楽しくはあるんだけど。`}</p>
      <p>{`んで、このAudioreaktは、Ableton Liveの最初から入っている機能をベースに本格的な音を作っているというのがすごい。単純に音素材としてと言うよりかは、勉強のための教材みたいな側面もある。現代の音楽ソフトの性能はすごくて、昔だったらタンスみたいなデカい機材でないと実現できなかったシンセサイザーを、フリーウェアで再現できるみたいな状態になってる。なので、音楽をやるためになにか追加でソフトを買わないとならないかと言われると、全然そんなことはない。結果的に自分は色々と買ってみてはいるものの、多分本当は、Liveだけ買えば他には何も要らない。理屈的には。Audioreaktの製品はそういうことを思い知らされるということで意味がある。`}</p>
      <p>{`結果的にそんなに使っているわけではないが、何もアイデアがない時にこのAudioreaktで買ったパックのパッチで音を鳴らしてみて、中身を見たりいじってみるなりすることがある。`}</p>
      <p>{`このAudioreaktのYoutubeチャンネルもかなり良い。内容はチャンネル名が示すとおりのもの。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.youtube.com/channel/UCK7cWGWuiVwcUPQLW-vhslw",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Youtube: All You Need Is Live`}</a></li>
      </ul>
      <h2 {...{
        "id": "novel-music-seeds"
      }}>{`Novel Music SEEDS`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/405955ca-686d-42cf-95cd-fc2f5e7882fc_polymath.png?auto=compress,format&w=2874&h=774",
          "alt": "SEEDSのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.novelmusic.org/m4l/seeds",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Novel Music SEEDS`}</a></li>
      </ul>
      <p>{`これはAudioreaktのYoutubeで紹介されていたAbleton Liveのプラグイン。これは、ステップシーケンサーをAbleton Liveの上でできるというもの。詳細は上記サイトを見てもらえれば雰囲気はわかると思われる。ポチポチnoteを置いていくと、その時間と音階で音がなるというモノ。`}</p>
      <p>{`これは必要なのか？それこそLiveのピアノロールで良くないか？と思われるものかもしれない。自分がなぜこれに魅力を感じたのかと言うと、DAWに向かってポチポチと音符的なものを打ち込んでいく作業は、果たして音楽をやっていることになるのかみたいな悶々とした気分があったため。そこからの脱却を色々試みるようになる。`}</p>
      <p>{`これは今も感じ続けていることなのだけれど、Ableton LiveみたいなDAWを開いて打ち込み音楽をするというのは、「編集している」という感覚に近い。人はいかにアイデアを曲にできるのか？ということについて、いくつも曲を作っているのに、自分は未だに分からない。このSEEDSのステップシーケンサーは、ある程度のランダム性の中から、自分の好きな音を探す役割をしてくれるのではと考えた。`}</p>
      <p>{`Fader Proを始めとしたいくつかの動画の中で、Happy accidentという言葉が使われていた。それは、楽器や音楽機材をいじっていると、なにか偶然でいい感じの音が出来て、それをそのまま曲にするというもの。自分の好きなアーティストが「自分の曲はほとんどハッピーアクシデントだよ。ハハハ」みたいなことを言っていて、まじで！？と自分は思ったのだった。なんか変な音が出る機材をいじってた、よくわからないけど出来た音で曲を作ってますということ。それをプロとして継続的に。まじか。`}</p>
      <p>{`このSEEDSは、Happy accidentを起こすシーケンサーみたいな側面がある。昔、ピアノが弾ける友人に「どうやって曲を作っているのか？」と聞いたことがあるのだけれど、「手クセみたいなところからメロディーが出てきて、それで作る」みたいなことを言っていた。自分は、楽器からではなくソフトウェアから音楽に入ったのでそういうものがなく、おそらく自分はそういう自分の楽器みたいなもの、音楽を作るための自分の方法を探してる感じになっている。思い返してみれば昔からそうだったのかもしれない。`}</p>
      <h2 {...{
        "id": "shaperbox"
      }}>{`Shaperbox`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/6078a9e7-72b0-4623-b866-bdcf4572d5fd_shaperbox.png?auto=compress,format&w=3640&h=2260",
          "alt": "Shaperboxのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.cableguys.com/shaperbox.html",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Cablebuys shaperbox`}</a></li>
      </ul>
      <p>{`前述Kickstartの会社のソフトでこれはありでは？って思ったので買ったモノ。`}</p>
      <p>{`Kickstartはキックのタイミングを際立たせるように、時間に合わせてボリュームを変化させるものだけれども、これはそれを自由自在に、いろんなエフェクトでいじれるやつ。機能的にはKickstartの完全に上位互換になる。どういう風に時間に応じて変化をつけるかを、グラフを書いて設定することが出来るので、それによって面白い音ができたりする。`}</p>
      <p>{`じゃあKickstartは要らないよねと考えていたけど、実際にはKickstartを使うことのほうがトータルとしては多い。Happy accidentだの何だのと考えていて、これはまさにHappy accidentを起こすヤツだなと思ったのだけれども、こういう、時間に伴うパラメータの変化をやりたいタイミングとして、キックを立たせるということがとても多いというのがまず1つの理由。なので、こういう音楽をやっている人間からすると、Kickstartは「あーコレよやりたいやつ、コレコレ」っていうアイデアプラグインであると言えそう。`}</p>
      <p>{`その他、これをあまり使っていない理由として大きいのが、これはグラフを書いて自由に色々できるわけだが、それは直感的と言うには程遠いという点。絵を描く時に、紙にペンで絵を書くのと、Adobe Illustratorを使うのではどちらが直感的かと言うのと似ている気がする。このshaperboxを使って何か面白い音が出来たとしても、そのプロセスは後者のIllustratorを使っているのに近い、「編集している」感覚になる。それやるんだったらKickstartを何重にも重ねたほうがなんか気分的には楽しい。`}</p>
      <p>{`ただ、「編集する」モードでDAWに向かっているときは、割と思ったように加工出来て良いという側面はある。例えばフィルタを開くのを繰り返すとかそういう。そんなわけで、アイデアの元にはなりづらいが、そういうアイデアを加工したり調整したりするという時には結構使えるという印象。`}</p>
      <h2 {...{
        "id": "fabfilter"
      }}>{`Fabfilter`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/2454cc73-7012-44c3-9682-80e0c50c6e27_fab.png?auto=compress,format&w=2220&h=1440",
          "alt": "fabfilterのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.fabfilter.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Fabfilter`}</a></li>
      </ul>
      <p>{`Fader Proで多くのアーティストが使っていたのを見て、全部入りを購入。結果、多分自分が一番使っているプラグインになってる。おそらく2022年に買ったソフトウェアの中でベストなものっぽい。自身の趣向を取り除き、客観的にフラットな視点で見た場合の評価として。多くのプラグインが年中セールをやるのに対して、この会社は年に数回しかセールをしないことからも、実績の高さが感じられる。自分は半年ぐらい待ってセールを待って買った。かなり値段がするものなので……。`}</p>
      <p>{`内容はいずれもよくあるプラグインがひたすら高品質で使いやすいUIという感じ。なので、例えばとりあえずEqualizerしたかったらPro-Qを使うし、Filter使いたかったらVolcanoを使うという具合になってる。`}</p>
      <p>{`高いものだけれども、たぶん安いのを色々買うならこれ買ったほうが良いんではと自分は感じた。`}</p>
      <h2 {...{
        "id": "native-instruments-traktor-kontrol-s4"
      }}>{`Native Instruments Traktor Kontrol S4`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/9bb686c8-afdd-40ae-8070-ab5ab72d1801_traktor.png?auto=compress,format&w=2612&h=2060",
          "alt": "Traktorのサイトキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.native-instruments.com/en/products/traktor/dj-controllers/traktor-kontrol-s4/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Native Instruments Traktor Kontrol S4`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/3WuiKss",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Amazon: Native Instruments Traktor Kontrol S4`}</a></li>
      </ul>
      <p>{`DJ機材です。音楽を続けているうちに、自分の昔から好きなレーベルにデモを作って送るということを目標にすることにした。それは言ってみれば学生時代からの夢のひとつみたいなモノだったけれど、何もなく曲を作るという状態よりも、何か目標があった方がやりやすいということを感じて、思いついてそうするようにした。`}</p>
      <p>{`そうなると、どういう曲を作りたいのかもフォーカスされてくる。で、自分の好きな音楽は、DJ mixの中で使われるのを前提としたものという要素がある。となれば、実際に他の曲と繋げてみればなにか分かることがあるのでは？と思ったりして、そのために買った。`}</p>
      <p>{`この機材は普段は使っていない。ただ、いくつか曲を作ったあと、他の曲と繋げてみると、色々発見があり、結果的に買ってよかったものになってる。自分の曲はごちゃごちゃ音を重ねすぎていて微妙とか、曲の前後にあるリズムだけのパートは重要とかそういう事を考えたり。これを使ってDJ的なことを楽しむのも一興っぽいが、結局そういう趣味には発展しておらず、多分自分は曲を作るのに向き合っていたいというのを再認識したみたいな意味合いもある。`}</p>
      <p>{`曲をつなげるのならDAW上でやったらいいのでは？とも思ったが、流れている曲に合わせて自分の曲を合わせて繋げるという体験が、意味があるっぽい気がした。`}</p>
      <h2 {...{
        "id": "v-moda-m200"
      }}>{`V-MODA M200`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.roland.com/jp/products/m-200/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Roland: V-MODA M-200`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/3VvbXxx",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Amazon: V-MODA M200-BK`}</a></li>
      </ul>
      <p>{`ヘッドホン。ヘッドホンは昔から色々と買っては試したりしていて、いくつも持っている。で、音楽を自分で作っていると、ひたすらに音量を上げたくなってくる。どうも、人は音量が大きい方が良い音楽に聴こえるみたいな話があるとかどうとか。で、テクノだののダンスミュージックだとおそらくそれが顕著で、具体的にはキックがずーんとくる感じを、作りながら感じられるかが重要っぽい気がしていた。`}</p>
      <p>{`曲がどうであれ、作っているモノが良く聴こえるに越したことは無い。DAWに向かい続けるテンションを保つためにも、今自分の耳に入ってくるものを良いと感じることは割と重要だと考えてる。じゃあどうすればいいのか、音楽を作る技術は別に考えるとして、どのヘッドホンだったら曲を作りやすいのかということについて考えていた。テクノみたいなやつの場合、スピーカーから音を出すと、周りに家が全然無い田舎の一軒家みたいな場所じゃないと、そういう音量を出せないと思う。なので「曲を作り続けるテンションになれるヘッドホンはどれか」みたいな命題になっていく。`}</p>
      <p>{`で、秋葉原にあるeイヤホンという、超たくさんのヘッドホンがあるお店で、一日中あれやこれや聴き比べ、これが自分にとっては一番良いと決めたのがこのV-MODA M200っていうヘッドホン。音がどう聞こえるのかということについて、自分は専門家では無いのでうまいこと表現できるつもりは無いけれども、なんかこのヘッドホンだと、低音〜中音が強調された感じ（しかしわざとらしくはない）で、EDMだと割と主となるその音域が前面に出てくる感じがしてこれが一番良いってなった。ただ、これは主観です。でも主観が重要。`}</p>
      <p>{`このヘッドホンを選べたのは、ひとえにeイヤホンという店舗様のおかげなので、関東に住んでてヘッドホンを悩む人は是非行ってみると良いと思う。こういうのって、ネットで見てても分かりようがないと思った。以下参考。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://e-earphone.blog/?p=1334436",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`まさに世界一のイヤホン・ヘッドホンのテーマパーク！2019年11月に再リニューアルしたeイヤホン秋葉原店に行ってみた！`}</a></li>
      </ul>
      <h2 {...{
        "id": "zerodebug-touchable-pro"
      }}>{`Zerodebug touchAble Pro`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/16f821bc-88b3-4b2a-ad02-1a1283784dcc_touchable.png?auto=compress,format&w=2732&h=2048",
          "alt": "touchAble Proのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://zerodebug.com/#/touchable",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Zerodebug touchAble Pro`}</a></li>
      </ul>
      <p>{`これはiPadでAbleton Liveをコントロールすることが出来るソフト。Ableton Liveを使っている人でこのソフトを知らなかった人は、是非Youtubeでこのソフト名で検索して、見てみて欲しい。Liveのほとんどあらゆる機能を、iPad上で分かりやすく表示、操作できる。`}</p>
      <p>{`このソフトは、Liveの操作上の悩みを綺麗サッパリ解決してくれるものだった。それは、はじめに書いたように、何かしらのMIDIコントローラーで直感的に操作したいという思い。Push2こそがそれを解決してくれたとそれまでは思っていたのだが、自分の中ではこのtouchAble Proが勝利した。`}</p>
      <p>{`例えば、Delayみたいなエフェクト一つを例に上げると、Live標準のSimple Delayは、左の拍数／右の拍数／フィードバック／モード／Wet……など、色々なパラメータがある。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/5a43f58c-9b45-4c36-aaf1-6ebc69aa01ed_delay.png?auto=compress,format&w=1162&h=664",
          "alt": "Delayのキャプチャ"
        }}></img></p>
      <p>{`これをMIDIコントローラーで操作したかったとしても、完全に直感的にそれを達成するのは難しい。なぜなら、まずMIDIコントローラーにはパラメーターの数ほどツマミは無いから。仮に超大量のツマミを持つMIDIコントローラーがあったとしても、今度は、どのツマミがどのパラメーターを操作するものなのか？というのが直感的ではない。これはPush2でも同様で、Push2には小さいディスプレイも付いているものの、同時に操作できるパラメーターには限りが有り、Push2と、画面に表示されている表示を頭の中で結びつける必要が出てくる。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/72b0b317-94b4-4f97-93d2-97d7eba0b56e_push2-delay.png?auto=compress,format&w=2842&h=1168",
          "alt": "Push2のDelay写真"
        }}></img></p>
      <p>{`これがtouchAble Proならどうだろう。なんと画面に表示された通りのUIがiPadに表示され、それを触れば画面も反応するわけだ。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/f3e63282-da21-4ae9-8318-ca50d28ef882_touchable-delay.png?auto=compress,format&w=2732&h=2048",
          "alt": "touchAble ProのDelayキャプチャ"
        }}></img></p>
      <p>{`これ以上直感的なものはないんじゃないか？ Push2はたしかに素晴らしいのだが、用意されたツマミやボタンで足りないものは、ページを切り替えるみたいな方法で解決している。Push2のようなハードウェアは、ツマミやボタンを物理的に操作できるという点が大きな利点だとは感じるが、iPadのようなタッチデバイスによる操作は、それと同じか、もしくはそれ以上の価値があるように感じられた。`}</p>
      <p>{`そんなわけで、大絶賛で紹介したいtouchAble Proであるが、残念なことにこれを開発している会社は2年ぐらい何も音沙汰が無い状態になっている。公式のフォーラムでもこのソフトを絶賛するとともに、開発が継続されていないことに対する悲しみのコメントに満ちている。そういう点で非常に残念。価値のあるソフトウェアなので、もっと高い価格で売り、ビジネス的にも成功して継続して欲しかった of 2022だった。`}</p>
      <p>{`そういう側面があるので、新しめのLive謹製プラグインはtouchAble Pro上で動かなかったりする。しかしながら、現状でもだいぶ使える範囲で動いているので、自分はこのソフトを使いたいがために、古い型のiPadを3台買った。高いスペックを求められるソフトでは無いので、中古で2万円とかそこらのiPadで十分。iPadを並べて、それぞれにtouchAble Proでミキサーを表示しておくぐらいの使い方でも十分に価値があるので、家でガッツリ作業する時はそうしている。このtouchAble Proは、タッチデバイスがハードウェアを凌駕したみたいなものを感じないでもない。`}</p>
      <h2 {...{
        "id": "imaginando-lk"
      }}>{`imaginando LK`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/06de0274-74a9-4bcb-919c-697a4fa65f8b_lk1.png?auto=compress,format&w=2732&h=2048",
          "alt": "LKのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.imaginando.pt/products/lk-ableton-live-and-midi-controller",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`imaginando LK`}</a></li>
      </ul>
      <p>{`そんな最高だけと残念なtouchAble Proなのだけれど、同じことを実現させようとしているのがこのLKというiOS用のソフト。こちらは今も開発が継続されている。`}</p>
      <p>{`このソフトには期待したかったが、自分が使った感想としては、残念ながらtouchAble Proには遠く及ばない。悪くなはいけれど、おそらくtouchAble Proの出来が良すぎて、どうしても比較してしまい、これはダメだなと感じてしまう。ほか、使っているとどうしてデバッグ段階でこんな不具合が潰れていないのかみたいなことにも出くわしたりして、今後に期待といったところ。本当にこの分野は頑張ってほしい。`}</p>
      <p>{`ただ、部分的に使いやすい部分もあるので、単純なMIDIコントローラーやMIDIパッドとしては今後もちょいちょい使っていくかもしれない。MIDIパッドはこれ。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/895a1a93-321f-4f3b-9760-82d06c49e3f2_lk2.png?auto=compress,format&w=2732&h=2048",
          "alt": "MIDIパッドのキャプチャ"
        }}></img></p>
      <h2 {...{
        "id": "devicemeister-stepic"
      }}>{`Devicemeister Stepic`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/1e520e3c-8d54-4f09-a1b2-918bcb2bc25d_stepic.png?auto=compress,format&w=3050&h=2290",
          "alt": "Stepicのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://devicemeister.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Devicemeister Stepic`}</a></li>
      </ul>
      <p>{`Novel Music SEEDSの項で、作り方を探してるみたいな話を少し書いたが、その後、自分の音楽への向き合い方は「いかにHappy accidentを起こし、それをキャプチャするか」みたいな方向性に変化していった。で、このStepicというプラグインは、ランダムにシーケンスやパラメーターを作ることが出来るシーケンサー的なプラグイン。`}</p>
      <p>{`ランダムに何かをしてくれるものというのは、それなりに色々とある。ただ、完全にランダムであっても、自分がそれに介入する要素があまりなくて面白みがないみたいな側面もあったりする。そういう中で、このStepicというのは、基本はシーケンサーだが、ランダムにも出来るという具合で色々できるため、気に入った部分だけを固定してあとはサイコロを振って決めるみたいなことができる。`}</p>
      <p>{`メロディーから曲を作るような人がこれを見ても、これでどうするってわけ？と思いそうだが、自分の音楽はこういう方法で作るのが割と直感的なようにも感じる。ソフトウェアでシーケンスを組み立てるモノの中では、かなり良いものだと思う。`}</p>
      <h2 {...{
        "id": "vcv-rack"
      }}>{`VCV Rack`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/9568de80-4a7f-41b2-9f9d-9523d0ca97c0_vcv.png?auto=compress,format&w=4070&h=2564",
          "alt": "VCV Rackのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://vcvrack.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`VCV Rack`}</a></li>
      </ul>
      <p>{`VCV Rack！ このソフトに出会って自分の音楽への向き合い方でモヤモヤしていたものが色々理解できてしまった。これは、ユーロラックをソフトウェアで再現するもの。ユーロラックというのはこういうやつ。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.google.co.jp/search?q=eurorack&tbm=isch&ved=2ahUKEwinoKmD2Zz8AhWDUPUHHXLnAsYQ2-cCegQIABAA&oq=eurorack&gs_lcp=CgNpbWcQAzIECCMQJzIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDoHCAAQgAQQGDoECAAQQ1CgA1idDWDNDmgAcAB4AIABXogB-AWSAQE5mAEAoAEBqgELZ3dzLXdpei1pbWfAAQE&sclient=img&ei=vGmsY6fSJYOh1e8P8s6LsAw&bih=603&biw=514&hl=en",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Google画像検索: eurorack`}</a></li>
      </ul>
      <p>{`小さなモジュールをケーブルで色々つなぎ合わせて、自分でシンセサイザーができるみたいな機材。こういうものは、ソフトウェアシンセサイザーの中でちょいちょい見かけるものも、「ふ〜ん、でもなんでソフト上でそんなめんどくさいことしないといけないわけ？」と自分は感じていた。だって、ソフトウェアは進化して、そういうややこしいことをしなくても、いい感じのプリセットが色々ある。それに、わざわざケーブルを繋がなくても、すでにそういうケーブルがすでに繋がれている状態である「パラメーターが無数にあり、いかようにも調整可能なように出来上がっているシンセサイザー」のソフトが世の中には腐るほどあるではないかと。ケーブルをつなぐという行為をモニタ上ですることは、単なる哀愁ではないのか？と。`}</p>
      <p>{`ところが、このVCV Rackを触ってみて、自分にとってそれは誤った考え方であったと気付く。このVCV Rackを使うためには、シンセサイザーの基本的な知識を身に着けなければならない。そういうのは高校の頃から色々と本を読んで知識としてはあったのだが、単純に「知っていただけ」であると思わされた。VCV Rack上で、小さなモジュールを一つ一つ選んで理解しながら組み上げていくことは、それ自体が音を作るという体験そのものであるみたいな感覚を自分にもたらしたのだった。言ってみれば、我々が普段使うシンセサイザー（ソフトでもハードでも）は、出来上がっているレトルト食品であって、このVCV Rackは、自分で材料を選んで一から調理している感じというような差を感じた。`}</p>
      <p>{`さらに、これをいじっている時に、自分は完成したシンセサイザーのパッチが欲しかったわけではないことにも気付く。このVCV Rackを使っていると、ちいさなサイン波が鳴るだけみたいなモジュールを一つ一つ組み合わせて全体を構成することになるわけだけれど、そういうのを組み合わせて出来た変な音みたいなものの中に、自分の欲しかった音というのが存在していた。それはおそらく、出来上がっている「どこかで聞いたことのある音」からたどり着くのは難しいものだったっぽい。じゃがいも抜きのカレーを求めている際、レトルトカレーからじゃがいもを抜くという作り方をしないだろうみたいなことに近いと思う。`}</p>
      <p>{`さらにVCV Rackには、無数のモジュールが、その多くが無料で利用できる、コミュニティによるボランティア精神に溢れたある世界でもあった。そのモジュールの中には単純なものも多いが、実際にユーロラックのハードウェアとして販売しているモジュールをそのままVCV Rackモジュールに移植したものなどもあり、そういうものは、それ一つを理解して使うだけでも、たくさんの知識が要求されたりする。それらをどのように組み合わせるのかというところもアイデアであって、この先は無限に広がっており、なんというか、新しい世界を見つけてしまった感があった。`}</p>
      <p>{`VCV Rackについて書くときりがないのだけど、割とこれをいじってること自体が、自分にとって音楽をやっている感覚をもたらすという側面があったという点もまた発見だった。`}</p>
      <p>{`自分はVCV Rackについて解説してる動画をよく見てる。あと、無料でも使えるので興味ある方は是非触ってみると良いかと思う。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.youtube.com/results?search_query=vcv+rack",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Youtube検索: VCV Rack`}</a></li>
      </ul>
      <h2 {...{
        "id": "mirack"
      }}>{`miRack`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/1de73ace-aa3f-47bb-b1b6-f5379c3bd286_mirack.png?auto=compress,format&w=2732&h=2048",
          "alt": "miRackのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://mirack.app/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`miRack`}</a></li>
      </ul>
      <p>{`そんなVCV Rackを、なんとiPadで使えてしまうというのがmiRackというソフト。このmiRackの魅力はVCV Rackと共通であると言えるが、それがiPadで出来るというのがすごい。iPadでそんなことできちゃうの？という。`}</p>
      <p>{`自分がこのmiRackに魅力を感じるのは、机に向かわなくても音楽に割とまともに向き合えるという点かもしれない。曲を作るとなると、基本的にはAbleton Liveを開いてよーしやるぞーって気分になる必要があるのだけれど、そこまでのテンションじゃない時になんかうまいことできないかと考えることがよくある。TVに向かってPS5でガッツリゲームをやるのもいいけど、iPhoneでしょーもないゲームをなんとなくやりたいとか、Switchでゴロゴロインディーゲームをやりたいみたいな、そういう気分の差？かもしれない。`}</p>
      <p>{`例えばカフェでボケーっとしながら音楽できないかなーとか。Macに向かわずに。そういう時に、iPadで音楽できまっせ〜みたいなアプリをなにか試してみても「うん、まぁ楽しいかもしれないけど、実際に曲作る時には使えないよね」みたいなケースがとっても多い。トータルで考えると、最初からLiveでやったほうが便利なんだわみたいな一線を越えられない。しかし、このmiRackはそうではなかった、今絶賛したVCV Rackがそのまま、iPad上でも動くのに近いのであった。なんということ。`}</p>
      <p>{`ただ、miRackはVCV Rackと比較すると、使えるモジュールが圧倒的に少ないという弱点がある。あくまでコミュニティの中心はVCV Rackというソフトウェアであり、miRackにこのモジュールを移植するには、そのために開発が必要らしい。なので、VCV Rackでこれいつも使ってる！というモジュールの多くがmiRackには無かったりする。ただ、前述したように、このVCV Rackの世界では、一つのモジュールに多数の機能が詰まっていたりするので、miRackで出来ることだけに絞られたとしても出来ることはとても多く、自分にとってのmiRackは学習素材という側面が結構強い。モジュールの追加は、miRackの今後に期待したいポイントと言える。`}</p>
      <p>{`そんなわけで、まぁmiRackは良くはあるものの、実際に曲作るときはVCV Rackの方でやるかなって感じだったのだが、後述するAUMでmiRackの株が上がった。それはAUMの項で。`}</p>
      <h2 {...{
        "id": "duet"
      }}>{`duet`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/9bb08f33-8057-457e-a126-15636ca212d1_duet.png?auto=compress,format&w=2302&h=1722",
          "alt": "duetのキャプチャ"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://ja.duetdisplay.com/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`duet`}</a></li>
      </ul>
      <p>{`このduetは、iPadを外部モニタとして扱うようにするためのソフト。別に音楽ソフトではないのだけれども、なぜこれを挙げたかと言うと、touchAble Proの代替を探していたため。`}</p>
      <p>{`touchAble Proはもう開発されていなくて、これは受け入れるしか無い現実として考えなければならない。じゃあどうするかということで考えたのが、iPad＋Apple Pencilで、画面をそのまま操作できたらいいのでは？というアイデア。この方法は、自分的にはかなり良いと感じていて、DAWをいじる人全般に勧めたいやつでもある。`}</p>
      <p>{`「Macだったら何も入れなくてもiPad外部モニタにできますよ」と突っ込まれそうだが、残念ながらその方法だと思ったほどうまくはいかない。duetであればMac OSXのアクセシビリティ機能でcontrolを押しながらのスクロールでの画面ズームを有効にし、画面をiPadにミラーして、いじりたいUIを画面いっぱいにし、Apple Pencilで操作することが可能。これで、touchAble Pro以上に直感的に画面の内容を操作できる。これはMac標準の、iPadを外部スクリーンにする機能だとできない。`}</p>
      <p>{`DAWのプラグインは、実際の機材を模したUIで作られているものがたくさんある。例えばこういうノリのUI。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/d9bdbdcd-7557-47eb-867f-880e5acede3a_punchbox.png?auto=compress,format&w=2568&h=1170",
          "alt": "Punchboxのキャプチャ"
        }}></img></p>
      <p>{`こういうものは、見た目は良いものの、実際にはツマミを上下させるには、マウスをチクチクいじったり、マウスホイールで頑張るみたいなものになる。長時間Macに向かっていてそういう作業をしていると、それが非常に疲れる原因になっているということに気付いてきた。また、touchAble Proの項で書いた、画面とMIDIコントローラーは完全一致しないという問題もこれだと解決される。`}</p>
      <p>{`一応、モニタ側でその問題を解決する以下のような商品もある。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.slatemt.com/raven-mti2/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Raven MTI2`}</a></li>
      </ul>
      <p>{`値段もぶったまげるほど高いという程でもなく、割とアリとは思うものの、これだと場所も取るし、何よりiPadならもともと家にあるし、ズームもできるしって感じで、割と融通がきいて便利だと思う。`}</p>
      <p>{`Tips: Ableton LiveにはPreferenceの中にtablet modeみたいのがあって、それを有効にするとこういうタッチデバイスでスムーズに使える`}</p>
      <h2 {...{
        "id": "bittradeone-rev-o-mate"
      }}>{`BitTradeOne Rev-O-mate`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/3f982f3c-e345-4e96-b515-576b60fd21c5_rev-o-mate.png?auto=compress,format&w=1354&h=992",
          "alt": "Rev-O-mateの写真"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://bit-trade-one.co.jp/product/bitferrous/bfrom11/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`BitTradeOne Rev-O-mate`}</a></li>
      </ul>
      <p>{`直感的にツマミ等を操作したい探求は続く。色々と探して試す中で、これかなりいいぞとなったのがこのUSBのデバイス。これもかなりオススメしたい。デザインや映像の仕事してる人にとっても割といいんじゃなかろうか。このRev-O-mateがすごいところは、ボタンやツマミをカスタマイズできるソフトが、とんでもなく融通が聞くというところ。これがLiveと相性が良い。`}</p>
      <p>{`例えば、多くのプラグインはツマミやフェーダーがマウスホイールで操作できるように作られているのだが、Live謹製のプラグインについては、ツマミがマウスホイールで操作できない。なぜか知らないがそのような作りになっていて、これは多くのユーザーが不満に感じていると思われる。（フォーラムでもそういう要望があるものの、長年スルーされている）`}</p>
      <p>{`なので、我々ユーザーとしては、`}</p>
      <ol>
        <li parentName="ol">{`mousedownしてドラッグ開始`}</li>
        <li parentName="ol">{`上にmousemove`}</li>
        <li parentName="ol">{`mouseupしてドラッグ終了`}</li>
      </ol>
      <p>{`みたいな操作をして、ボリュームを上げるとかそういう操作を行わないとならない。こういうのをやりたくなくて前述のiPad＋duetでなんとかしてきたというのが前項の話なわけだが、このRev-O-mateだと、ホイールの右回転に「マウス座標をy軸で5px上に移動」みたいな挙動を割り当てられるので、要するに画面で今マウスがある位置にあるツマミをこのRev-O-mateでいじることが可能となる。`}</p>
      <p>{`それはつまり、MIDIコントローラーを使うことと同じなのでは？と言われれば、微妙に違う。MIDIコントローラーだと、初めの方に書いた通り、いちいちパラメーターをアサインしないとならないので、その点、「今マウスポインタがある場所」のツマミをいじれるRev-O-mateの方が、個人的には操作性が良いように感じる。`}</p>
      <p>{`その他、VCV Rackは、割とUI操作に癖がある。ctrlやらcommandやらをクリックしながらのドラッグが求められたり、スクロール上下移動とノブの左右回転が逆という挙動になっていたり、Macのポインター移動の慣性が効いてしまってツマミの回転がいまいち思うように行かないみたいな問題がある。基本Windowsベースで作られているからMacではそういう微妙さがあるのか、ソフトの作り的に難しいのかよく分からないのだけれど、とにかくそうなっていて、UIの操作性としてはだいぶ難あり。`}</p>
      <p>{`そういうソフトウェアのUIの微妙さを、このRev-O-mateは大分解決してくれる。ホイールの横にたくさんボタンがついていて、それらに好きなキーを割り当てることが出来たり、設定のプリセットを切り替えられたり、ホイールの回転とスクロールのスピードもとても細かく設定できたりなど、とっても柔軟。`}</p>
      <p>{`そういうのをiPad＋duetで解決しようとしたのだけれども、単純に画面上のツマミなりをちょいちょっとだけいじる時や、commandやcontrolを押しながらの操作は、Rev-O-mateとトラックパッドの組み合わせの方が便利だったりもするので、場合によってiPad＋duetと、このRev-O-mateを使い分けて操作する感じにしている。`}</p>
      <h2 {...{
        "id": "monome-grid--mark-eats-sequencer"
      }}>{`monome grid + Mark Eats Sequencer`}</h2>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/f12bfa6b-1f58-46b8-81ba-cf520d2f20b0_monome.png?auto=compress,format&w=3102&h=2142",
          "alt": "monome gridとかの写真"
        }}></img></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://monome.org/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`monome`}</a></li>
      </ul>
      <p>{`たくさんボタンがついているだけの謎のデバイス、monome grid。そしてRaspberry Piのガジェットmonome norns。興味があったのはmonome gridの方で、nornsの方はebayで落札する際に間違ってセットで買ってしまったものだったのだけれど、結果的にはこれがないとダメなやつだったので、一緒に買って正解だった。`}</p>
      <p>{`まずこのmonome grid、一体何？という感じなのだけれど、コレ自体はただこういう光る謎ボタンデバイスというだけであり、API用意してあるから好きに使ってくれというオープンソースなコミュニティドリブン精神のプロダクトとなっている。上記サイトに色々書いてあるものの、基本ドキュメントなので、正直、買う前に見てもよくわからないと思われる。`}</p>
      <p>{`自分がmonome gridを欲しいと思ったのは、以下のMark Eats Sequencerを見てのことだった。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.markeats.com/sequencer/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Mark Eats Sequencer`}</a></li>
      </ul>
      <p>{`これはパターンシーケンサーとかいうモノで、昔ながらのリズムマシンなんかに備わっている機能の一つだったりする。これと似たことはPush2でも出来たのだけれど、いかんせんPush2だと8x8の64つしかパッドが無い。自分は単純にそれをもっと広く使いたかった。イメージとしては、前述Novel Music SEEDSのコレと似たことをしたかった。`}</p>
      <p><img parentName="p" {...{
          "src": "https://images.prismic.io/takazudo-me/405955ca-686d-42cf-95cd-fc2f5e7882fc_polymath.png?auto=compress,format&w=2874&h=774",
          "alt": "SEEDSのキャプチャ"
        }}></img></p>
      <p>{`そんなことはSEEDSで出来るのだから、それでやればいいではないかという風にも思うが、それを実際のハードウェアでやれたら「楽器を演奏している」のに近い感覚を得られるのではないかと考えたのだった。前述した、「Illustratorで作業するより紙に絵を描きたい」みたいな感覚なのかもしれない。結果、割とそのように感じることが出来たので、予測はだいぶ当たっていた。`}</p>
      <p>{`このMark Eats Seqencerで打ち込んだMIDI notesは、そのままLiveに送っていて、結局鳴らすのはVCV Rackやらソフトシンセやらなのだが、ここでパターンを作って「これで行くか」というところまで決めて進めると、割とそこから後戻りはしたくない気分になる。最近、自分の曲の作り方が、Live以外のどこかでアイデアを練って、まとまってきたら曲にするというワークフローになっていて、これは割と良いように感じている。`}</p>
      <h2 {...{
        "id": "monome-norns--cheat-codes-2"
      }}>{`monome norns + cheat codes 2`}</h2>
      <p>{`オマケで買ってしまったmonome nornsは、それにOSSのソフトを入れたり、自分でコードを書いてソフトを作って楽しめるというもの。自分でソフトを作ろうなどとは毛頭考えておらず、何か良いのがないかと色々試した結果、cheat codes 2というものに出会う。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://norns.community/en/authors/dan_derks/cheat_codes_2",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`cheat codes 2`}</a></li>
      </ul>
      <p>{`これが、自分的に大ヒットした。`}<br parentName="p"></br>{`
`}{`このcheat codesがどんなものかは、以下の動画を見てもらうと雰囲気がわかるかもしれない。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://youtu.be/5P0vzHSHA0I",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Youtube: Ways I Use Cheat Codes; Patterns and timing`}</a></li>
      </ul>
      <p>{`このcheat codes 2はnorns上で動くソフトウェアなのだけれど、これは完全に「楽器である」と自分は感じる。このcheat codes 2を使うと、サンプルを細切れにし、順々に鳴らすことで曲を作ることが出来る。言葉にすると単純で、それは例えばiPadでよくある1,000円ぐらいのソフトでもできるのでは？ と言われれば、そうでもあるとも言える。じゃあ何がcheat codes 2を楽器だと感じさせているのか。`}</p>
      <p>{`おそらく、cheat codes 2では、そのような音作りを、いかに直感的に行えるかにフォーカスして作られているからであると自分は感じた。ボタンを押してポチポチ音を鳴らすシンプルさをベースにしつつ、それをソフトウェアでうまいこと拡張してくれる――そしてそれはランダム過ぎず、自分のコントロールできる範疇で行える――みたいな感じ？ の何かになっている。このバランス感がすごい！`}</p>
      <p>{`cheat codes 2に出会う前後では、よくあるループのサンプルに対する考え方が変わった。例えば先述したspliceでは、ロイヤリティーフリーのサンプル素材がたくさんダウンロードでき、その中にはループになっているものがたくさんある。例えばギターで何かしらのメロディーを奏でている音が16小節分みたいなもの。こういう素材について、自分はどのように使ったら良いのかがよく分かっていなかった。`}</p>
      <p>{`「ループ素材の使い方」と言われれば、それは本当単純な話ではある。単にループを並べて音を抜き差しすれば、まがりなりにも一つの楽曲っぽく聴こえるし、例えばyoutubeで配信する動画のBGMみたいなものであれば、それで作って満足かもしれない。ただ、そういうループを繋げたものを「自分の曲」として発表することは、どう考えればよいのか。借りてきた音を並べて「はい、これは私の作った曲です」と宣言することにはどういう意味があるんだろうか。`}</p>
      <p>{`それは、雑誌のページを集めてきて製本し、「はい、この本は私が作りました」と言っているのと近いのではないか？ もちろんそこには自分が選択して構成したという「キュレーション」的な意味はあるだろうとは思う。ただそれで何か利益を出してビジネスができるができると言うにはそういうものでもないし、じゃあ個人の趣味でそれをやる――つまり自己表現や楽しみのためにそれを行う――範囲においては、それは楽しいことなのか？音楽を作っていることになるのか？みたいな禅問答になっていた。自分の場合。`}</p>
      <p>{`おそらく、それは自分の音楽が、シンセサイザーやリズムマシン等の電子楽器を組み合わせて作る性質のものであるがゆえにそう感じるという部分なのかもしれなかった。例えば自分はラッパーで、何かしらのビートやメロディーはあくまで背景画像的な位置づけであれば、そうは考えないかもしれないし。結局音楽をやるってことは、自分が何を自分の音楽とたらしめるかを考えていくことなのかも知れないけど。`}</p>
      <p>{`話がそれたけれども、cheat codes 2を使っていると、そんな雑誌の切り抜きを集めてきて製本するという感覚ではなく、好きな部分を切り貼りしてコラージュを作っているような感覚を、自分は得る。なぜかよくわからないが、cheat codes 2でループを細切れにして再構成したものは、自分にとって明らかに「自分の作ったもの」と感じることが出来、そしてこれをいじっている事自体が楽しいものだった。おそらく、そのプロセスを直感的に行えるのだ。`}</p>
      <p>{`cheat codes 2でできることは、一応、他のソフトなりを使っても出来る。だが、実際に同じことをやろうとすると、その行為の結構な割合が「編集している」感のするものになってしまった。そんなcheat codes 2、自分にとってはgridとnornsを持ってカフェにいって、ずっとそれをいじってるみたいなことが可能。そしてそういうことができるコンパクトさがあるのも良い。`}</p>
      <p>{`音楽機材は、ソフトウェアとハードウェアどちらがいいのかという話がある。ごく端的に言うと、ソフトウェアは便利で効率的だが直感的ではなく、ハードウェアはその逆という特徴があるみたいな話。この2つを比べた場合、自分はソフトウェアの利便性の方が圧倒的に勝っている感覚だった。これは何度も考えてきたことだったが、例えばシンセサイザーひとつをとっても、録音のめんどくささや場所をとることを考えると、「いや〜どう考えてもソフトシンセでいいな……」という結論に毎回達する。MIDIコントローラーやらがあれば直感的な操作もできるわけで、自分はキーボードが弾けるわけでもないし、そもそもそういう風に弾いて作る類の音楽でもない。そうなると、ソフトの便利さを超える何かを、ハードに見いだせない。`}</p>
      <p>{`ところが、このcheat codes 2は、わざわざこれで音を作ってDAWで録音するという面倒な手間をかけても使いたいものであると感じて、これが自分にとって驚きだった。今まで一度も、ハードウェアがソフトウェアを凌駕していると実感したことが無かったのだ。（まぁcheat codes 2自体はソフトウェアだが、DAWの外側にある音楽機材という意味でハードウェア的と言える）`}</p>
      <p>{`このcheat codes 2のために以下のMIDIコントローラーを買い、あわせて使っている。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.midifighter.com/#Twister",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Midi Fighter Twister`}</a></li>
      </ul>
      <p>{`そして先述のMark Eats Sequencerも合わせて使いたかったので、monome gridを買い足して2つになった。間違って買ったnornsがこのソフトとの出会いをもたらしたので、ラッキーな偶然だった。monome gridが気になってこの記事にたどり着いたような人がいたら、併せてnornsも買うことを強く勧めたい。`}</p>
      <h2 {...{
        "id": "aum"
      }}>{`AUM`}</h2>
      <Youtube url="https://youtu.be/aKxzX2x4fGg" mdxType="Youtube" />
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://kymatica.com/apps/aum",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`AUM`}</a></li>
      </ul>
      <p>{`AUMは、iPadで使えるソフトである。miRackの項で、iPadには色々ソフトがあるけど、実際に使いはしないという話を書いた。ところが、このAUMを使うと、そういうiPad上で動作するソフトを組み合わせて、Liveのようなことができてしまう。これが結構衝撃的だった。`}</p>
      <p>{`前述のmiRackで不満だったのは、主にエフェクトがしょぼいからだった。miRackで使えるモジュールは限定的であり、その中でも例えばリバーブ／ディレイを使いたいと考えた時、選択肢が少ない。そして、実際に曲の中で使いたい時には、Liveで使えるfabfilterのリバーブやディレイをかけたい。d16のディストーションをかけたいみたいになる。`}</p>
      <p>{`こういう楽曲制作においては、「エフェクトは必要なものだけを選んで使おう」みたいな話があったりするのだが、自分の好きなタイプの音楽の場合、そうではなくて、エフェクトをどんな感じでどういう風に組み合わせて音を作るかみたいな要素が割と高いと感じる。めっちゃディストーションかけて潰したリズムがそもそもハードテクノの主要素みたいな。となると、iPadのmiRackではそれが完結できないので、Macと接続して作業することになる。だったらそもそもmiRackじゃなくてVCV Rackで良いよね？ という状態になっていた。`}</p>
      <p>{`そこでこのAUMというものなのだけれど、これは、miRackの出力を、別のエフェクトにつなげることが出来る。そういうトラックを複数作って、DAW的なことを可能にしてくれるものだった。プチLiveがiPadの中にある感じ。miRackのインスタンスを複数起動して、同時に鳴らすことが出来る。`}</p>
      <p>{`なぜそういう事ができるのかと言うと、まぁこのあたりは自分に大して知識がないのだけれど、iOSには、AUv3という仕様？に基づいて作られたそういう音楽系のアプリケーションは、別のアプリケーションから参照できるみたいな仕組みになっているらしい。そしてその入出力もアプリ内でやりとりできるため、それをうまいことDAWっぽくまとめ上げたのが、このAUMというソフト。AUMを使えば、例えば以下のような、エフェクト単体、シーケンサー単体、シンセサイザー単体を、自由にルーティングして使うことが出来る。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://apps.apple.com/us/app/koala-fx/id1518085697",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Koala FX`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://apps.apple.com/us/app/fugue-machine-midi-sequencer/id1014191410",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Fugue Machine : MIDI Sequencer`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://apps.apple.com/jp/app/korg-module/id932191687",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Korg Module Pro`}</a></li>
      </ul>
      <p>{`そうなると、iPad上で動くソフトの意味合いがだいぶ変わってくる。前述のmiRackも、イマイチだったエフェクトについて、それなりにいい感じのものを後ろに繋げれば、そのまま曲に使える音にすることもできる。そしてよく見ると、iPadには面白そうなシーケンサーのソフトが色々とある。これらは、単体だと大して意味をなさないものであったが、AUMの登場によって、Takazudo相場においてはその価値が急上昇した。`}</p>
      <p>{`miRack上だと結構面倒なシーケンサ部分を、別の、自分の好きなシーケンサーとつなげることも出来る。こうなってくると、割と本当にiPadだけでだいぶそれなりにそのまま使えるアイデアが練れる感じになってくる。そもそもmonome gridを買った動機は、Mac上でシーケンスを組み立てる以外の方法でアイデアを練りたかったというものであったのだが、そういう可能性が、このAUMの登場によってものすごく拡張された感覚を得ている。`}</p>
      <p>{`そして、iPadだけを持ってカフェに行っても割と色々できるという点もデカい。よくわからないが、自分にとって「カフェで何かできる」「ゴロゴロしながら出来る」というポイントがデカい。おそらく「編集モード」になっていない自分じゃないとなかなかアイデアを見つけることが出来ないという性質がある。`}</p>
      <p>{`その他、Windows／Macのソフトと比較すると、iPadで動く何かは比較的安価なので、気になる人がいじってみるという導入としても、わりとオススメかも知れない。（簡単であるとは言えないが）`}</p>
    </Body>
    <Outro mdxType="Outro">
      <p>{`文中に書いた、自分の好きなレーベルにデモを送るというのは、合計8曲作って2回送ってみたものの、何も返事が無く残念な結果だったので、まぁ2023年はもっと気楽にやっていこうかなと思ってる中。`}</p>
      <p>{`なんか面白そうだなと思った人にオススメしたいのはmiRack。1,000円ちょいなので。そして結構そういうのやってる人にオススメしたいのはmonome。ただしこちらはフツーに買うのは難しいので、自分はebayで買いました。そして使うにはエンジニア的勘も割と必要でハードル高め……。`}</p>
      <p>{`この記事、割とこれ系の音楽やってる人だとそこそこ役に立ちそうじゃないかと書いていて思ったので、何かお役に立つ人がいたら幸い。`}</p>
      <p>{`2022年前半でTakazudoが作った曲は`}<a parentName="p" {...{
          "href": "https://soundcloud.com/takazudo/takazudo-promo-mix-20220713",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`こちら`}</a>{`。`}</p>
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      